import { GameResult, PlayerResult } from '@server/src/states/GameResult'
import { UI_DEPTH } from '../../const/layers'
import { PostLobby } from '../../scenes/PostLobby'
import { ResultBox } from './ResultBox'

export class Result extends Phaser.GameObjects.Container {
	scene: PostLobby

	constructor(scene: PostLobby, p: GameResult) {
		super(scene, scene.cameras.main.width / 2, scene.cameras.main.height + 1000)
		this.scene = scene

		const bg = scene.add.nineslice(0, 0, 'blue_panel', undefined, 1200, 700, 30, 30, 30, 30)
		this.setSize(bg.width, bg.height)

		const box1 = new ResultBox(scene, -bg.width / 4, 'playername', 0, 'buffalo').setName(`result_0`)
		const box2 = new ResultBox(scene, bg.width / 4, 'playername', 0, 'buffalo').setName(`result_1`)

		this.add(bg)
		this.add(box1)
		this.add(box2)

		const players = p.players
		this.updateResults(players)
		this.setDepth(UI_DEPTH)
		scene.add.existing(this)
	}

	updateResults(players: PlayerResult[]) {
		for (const player of players) {
			const resultBox = this.getByName(`result_${player.slot}`) as ResultBox
			if (resultBox) {
				resultBox.updateResult(player)
			}
		}
	}

	show(scene: Phaser.Scene) {
		scene.tweens.add({
			targets: this,
			y: scene.cameras.main.height / 2,
			duration: 1000,
		})
	}
}
