import { Bodies, World } from 'matter-js'
import { characterCategory, ignoreCategory } from './colliders'
import { WORLD_SIZE } from './game'

export const WORLD_GRAVITY_Y = 0.15
export const WORLD_GRAVITY_X = 0

export const createWorldBounds = (world: World) => {
	const thickness = 128 // Thickness of the world bounds

	// Top boundary
	const top = Bodies.rectangle(WORLD_SIZE.width / 2, -thickness / 2, WORLD_SIZE.width, thickness, {
		isStatic: true,
		label: 'bounds',
		collisionFilter: {
			category: ignoreCategory,
			mask: ignoreCategory | characterCategory, // Collide with ignore and character categories
		},
	})

	// Bottom boundary
	const bottom = Bodies.rectangle(
		WORLD_SIZE.width / 2,
		WORLD_SIZE.height + thickness / 2,
		WORLD_SIZE.width,
		thickness,
		{ label: 'bounds', isStatic: true }
	)

	// Left boundary
	const left = Bodies.rectangle(-thickness / 2, WORLD_SIZE.height / 2, thickness, WORLD_SIZE.height * 10, {
		isStatic: true,
		label: 'bounds',
	})

	// Right boundary
	const right = Bodies.rectangle(
		WORLD_SIZE.width + thickness / 2,
		WORLD_SIZE.height / 2,
		thickness,
		WORLD_SIZE.height * 10,
		{ isStatic: true, label: 'bounds' }
	)

	// Add boundaries to the world
	World.add(world, [top, bottom, left, right])
}
