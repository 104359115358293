import { Player } from '@server/src/states/Player'
import { getAuth } from '../../../discord/auth'
import { MainMenu } from '../../scenes/MainMenu'
import { Character } from './Character'

export class SceneCharacterManager {
	scene: MainMenu
	constructor(scene: MainMenu) {
		this.scene = scene

		this.scene.events.on('P_SelectCharacter', (p: { x: number; y: number; data: Player }) => {
			this.spawn(p.data.userId, p.data.character, scene, p.x, p.y)
		})

		this.scene.events.on('P_SpawnSelectCharacters', (p: { data: Player[] }) => {
			this.spawnPlayers(p.data)
		})
	}

	spawn(userId: string, texture: string, scene: MainMenu, x: number, y: number) {
		const possibleExistingCharacter = scene.children.getByName(`Character_${userId}`)
		if (possibleExistingCharacter) {
			possibleExistingCharacter.destroy(true)
		}
		scene.character = new Character(scene, x, y, texture, userId)
	}

	spawnPlayers(players: Player[]) {
		for (const player of players) {
			const possibleExistingCharacter = this.scene.children.getByName(`Character_${player.userId}`)
			if (possibleExistingCharacter) {
				possibleExistingCharacter.destroy(true)
			}

			if (player.userId === getAuth()?.user.id) {
				this.scene.character = new Character(
					this.scene,
					player.slot === 0 ? 300 : this.scene.cameras.main.width - 300,
					this.scene.cameras.main.height / 2,
					player.character,
					player.userId
				)

				this.scene.character?.showEmote('emote_faceAngry')
			} else {
				new Character(
					this.scene,
					player.slot === 0 ? 300 : this.scene.cameras.main.width - 300,
					this.scene.cameras.main.height / 2,
					player.character,
					player.userId
				)
			}
		}
	}
}
