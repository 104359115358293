import { State } from '@server/src/states/State'
import { Room } from 'colyseus.js'
import { removeCustomEventListeners } from '../../misc/events'
import { PostLobby } from '../../scenes/PostLobby'

export class PostLobbyEventManager {
	room: Room<State>
	scene: PostLobby

	constructor(scene: PostLobby) {
		this.room = scene.registry.get('room')
		this.room.removeAllListeners()

		removeCustomEventListeners(scene)
		this.scene = scene

		this.S2CListeners()
		this.PListeners()
	}

	S2CListeners() {}

	PListeners() {
		this.scene.events.on('P_LeaveToLobby', () => {
			console.log('P_LeaveToLobby')
			this.scene.scene.start('MainMenu')
		})
	}
}
