import { Player } from '@server/src/states/Player'
import { getAuth } from '../../../discord/auth'
import { Game } from '../../scenes/Game'
import { Character } from './Character'

export class GameCharacterManager {
	scene: Game
	constructor(scene: Game) {
		this.scene = scene
	}

	spawnPlayers(players: Player[]) {
		const sorted = players.sort((a, b) => a.slot - b.slot)
		for (const player of sorted) {
			const possibleExistingCharacter = this.scene.children.getByName(`Character_${player.userId}`)
			if (possibleExistingCharacter) {
				possibleExistingCharacter.destroy(true)
			}

			if (player.userId === getAuth()?.user.id) {
				this.scene.character = new Character(this.scene, player.x, player.y, player.character, player.userId)
			} else {
				new Character(this.scene, player.x, player.y, player.character, player.userId)
			}
		}
	}
}
