import { characterCategory, defaultCategory, ignoreCategory } from './colliders'

export const WORLD_SIZE = {
	width: 1920,
	height: 1080,
}

export const CHARACTER_CIRCLE_SIZE = 90

export const CHARACTER_MOVE_SPEED_X = 8
export const CHARACTER_MOVE_SPEED_Y = 9

export const CHARACTER_START_POS_0 = { x: WORLD_SIZE.width / 2 - 300, y: 500 }
export const CHARACTER_START_POS_1 = { x: WORLD_SIZE.width / 2 + 300, y: 500 }

export const CHARACTER_OPTIONS = {
	restitution: 0.005, // Bounciness
	friction: 0, // Friction
	density: 10000,
	mass: 5000,
	inertia: Infinity,
	collisionFilter: {
		category: characterCategory,
		mask: defaultCategory | ignoreCategory | characterCategory, // Collide with default, ignore, and character categories
	},
}

export const BALL_SIZE = 130
export const BALL_MASS = 10

export const BALL_OPTIONS = {
	restitution: 0.75, // Bounciness
	friction: 0.01, // Friction
	density: 0.001, // Density
	//inertia: Infinity, // Fixed rotation
	mass: BALL_MASS,
	collisionFilter: {
		category: defaultCategory,
		mask: defaultCategory | characterCategory, // Collide with default and character categories
	},
}
