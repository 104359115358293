import { Game } from '../../scenes/Game'
import { MainMenu } from '../../scenes/MainMenu'

export class InputManager {
	keyA: Phaser.Input.Keyboard.Key
	keyD: Phaser.Input.Keyboard.Key
	keyW: Phaser.Input.Keyboard.Key
	keyS: Phaser.Input.Keyboard.Key

	scene: MainMenu | Game

	constructor(scene: MainMenu | Game) {
		this.scene = scene
		if (!this.scene.input.keyboard) {
			throw Error('Keyboard is not enabled. You must provide a valid Keypoard as the core input device.')
		}
		this.keyA = this.scene.input.keyboard.addKey('A')
		this.keyD = this.scene.input.keyboard.addKey('D')
		this.keyW = this.scene.input.keyboard.addKey('W')
		this.keyS = this.scene.input.keyboard.addKey('S')
	}

	handleInput() {
		if (this.keyA.isDown) {
			this.scene.character?.left()
			this.scene.events.emit('P_KeyboardInput', { data: 'A' })
		}
		if (this.keyD.isDown) {
			this.scene.character?.right()

			this.scene.events.emit('P_KeyboardInput', { data: 'D' })
		}
		if (this.keyS.isDown) {
			this.scene.character?.down()

			this.scene.events.emit('P_KeyboardInput', { data: 'S' })
		}
		if (this.keyW.isDown) {
			this.scene.character?.up()

			this.scene.events.emit('P_KeyboardInput', { data: 'W' })
		}
	}
}
