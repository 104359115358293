const cursorPivotX = 20
const cursorPivotY = 20

const cursorPath = '/img/cursor/'

export const useCursorPointer = (scene: Phaser.Scene) => {
	scene.input.manager.canvas.style.cursor = `url('${cursorPath}hand_point.png') ${cursorPivotX} ${cursorPivotY}, default`
}

export const useCursorOpen = (scene: Phaser.Scene) => {
	scene.input.manager.canvas.style.cursor = `url('${cursorPath}hand_open.png') ${cursorPivotX} ${cursorPivotY}, pointer`
}

export const useCursorGrab = (scene: Phaser.Scene) => {
	scene.input.manager.canvas.style.cursor = `url('${cursorPath}hand_closed.png') ${cursorPivotX} ${cursorPivotY}, grab`
}

export const applyCursorBehaviour = (object: Phaser.GameObjects.GameObject) => {
	const { scene } = object
	object
		.on('pointerout', () => {
			useCursorPointer(scene)
		})
		.on('pointerover', () => {
			useCursorOpen(scene)
		})
		.on('pointerup', () => {
			useCursorOpen(scene)
		})
		.on('pointerdown', () => {
			useCursorGrab(scene)
		})
}
