export class Background extends Phaser.GameObjects.TileSprite {
	constructor(scene: Phaser.Scene, textureKey: string = 'backgroundColorFall') {
		super(scene, 0, 0, scene.cameras.main.width, scene.cameras.main.height, textureKey)

		this.setOrigin(0, 0)
		this.setScale(1.1)
		this.setDepth(-50)
		scene.add.existing(this)
	}
}
