const isClientSideLocal = () => {
	return window.location.host.includes('localhost')
}

export const getColyseusURL = () => {
	const isLocal = isClientSideLocal()
	const host = isLocal ? 'localhost:4000/colyseus' : window.location.host
	const socketPrefix = isLocal ? 'ws' : 'wss'
	return `${socketPrefix}://${host}`
}
