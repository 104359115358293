import { PlayerResult } from '@server/src/states/GameResult'
import { PostLobby } from '../../scenes/PostLobby'
import { RubikText } from './RubikText'

const size = 300
export class ResultBox extends Phaser.GameObjects.Container {
	character: Phaser.GameObjects.Sprite
	winner: Phaser.GameObjects.Sprite

	playerNameText: RubikText
	scoreText: RubikText

	constructor(scene: PostLobby, x: number, playerName: string, score: number, characterTexture: string) {
		super(scene, x, 0)

		const frame = scene.add.nineslice(0, 0, 'blue_button06', undefined, size, size, 10, 10, 10, 10)
		this.setSize(frame.width, frame.height)

		this.character = scene.add.sprite(0, 0, characterTexture).setScale(0.9)
		this.playerNameText = new RubikText(scene, 0, frame.height / 2 - 45, playerName)
		this.scoreText = new RubikText(scene, 0, frame.height / 2 + 60, score.toString(), { fontSize: '72px' })

		this.winner = scene.add
			.sprite(0, -frame.height / 2, 'trophy')
			.setOrigin(0.5, 1)
			.setScale(2)
			.setVisible(false)
			.setTint(0xffd700)

		this.add(frame)
		this.add(this.character)
		this.add(this.playerNameText)
		this.add(this.scoreText)
		this.add(this.winner)

		scene.add.existing(this)
	}

	updateResult(playerResult: PlayerResult) {
		const { character, name: playerName, score, isWinner } = playerResult
		this.character.setTexture(character)
		this.playerNameText.setText(playerName)
		this.scoreText.setText(score.toString())
		this.winner.setVisible(isWinner)
	}
}
