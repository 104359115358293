import Phaser from 'phaser'
import { applyCursorBehaviour } from '../../misc/cursor'
import { RubikText } from './RubikText'

export class Button extends Phaser.GameObjects.Container {
	public background: Phaser.GameObjects.NineSlice
	public textObj: RubikText

	constructor(scene: Phaser.Scene, name: string, x: number, y: number, eventName: string, buttonText: string) {
		super(scene, x, y)
		this.setName(name)

		this.textObj = new RubikText(scene, 0, -5, buttonText).setOrigin(0.5, 0.5)
		this.background = scene.add.nineslice(
			0,
			0,
			'blue_button07',
			undefined,
			this.textObj.width + 150,
			this.textObj.height + 40,
			20,
			20,
			20,
			20
		)
		this.setSize(this.background.width, this.background.height)

		this.setInteractive()
			.on('pointerdown', () => {
				this.handleClick(eventName)
			})
			.on('pointerover', () => {
				this.background.setTint(0x2a1b3d)
			})
			.on('pointerout', () => {
				this.background.clearTint()
			})

		applyCursorBehaviour(this)
		this.add(this.background)
		this.add(this.textObj)

		scene.add.existing(this)
	}

	private handleClick(eventName: string) {
		this.scene.events.emit(eventName)
	}
}
