import { GameResult } from '@server/src/states/GameResult'
import { Scene } from 'phaser'
import { Background } from '../objects/environment/Background'
import { PostLobbyEventManager } from '../objects/events/PostLobbyEventManager'
import { Button } from '../objects/ui/Button'
import { Result } from '../objects/ui/Result'

export class PostLobby extends Scene {
	result!: Result
	leaveButton!: Button

	constructor() {
		super('PostLobby')
	}

	create(p: { data: GameResult }) {
		console.log('PostLobby')
		new Background(this)
		new PostLobbyEventManager(this)

		this.leaveButton = new Button(
			this,
			'LeaveToLobbyButton',
			this.cameras.main.width - 200,
			this.cameras.main.height - 50,
			'P_LeaveToLobby',
			'Back to Lobby'
		)

		this.result = new Result(this, p.data)
		this.result.show(this)
	}
}
