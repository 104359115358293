import { State } from '@server/src/states/State'
import { Room } from 'colyseus.js'
import { Scene } from 'phaser'
import { Character } from '../objects/characters/Character'
import { SceneCharacterManager } from '../objects/characters/SceneCharacterManager'
import { Background } from '../objects/environment/Background'
import { MainMenuEventManager } from '../objects/events/MainMenuEventManager'
import { InputManager } from '../objects/input/CharacterInputManager'
import { Button } from '../objects/ui/Button'
import { CharacterSelectPanel } from '../objects/ui/CharacterSelectPanel'

export class MainMenu extends Scene {
	character: Character | null = null
	room!: Room<State>

	inputManager!: InputManager

	constructor() {
		super('MainMenu')
	}

	create() {
		console.log('MainMenu')
		this.room = this.registry.get('room')

		new MainMenuEventManager(this)
		this.inputManager = new InputManager(this)

		new Background(this)
		new Button(this, 'StartGameButton', 1600, 650, 'P_StartGame', 'Start Game')

		new SceneCharacterManager(this)
		new CharacterSelectPanel(this)
	}

	update() {
		this.inputManager.handleInput()

		for (const player of Array.from(this.room.state.players.values())) {
			const possibleExistingCharacter = this.children.getByName(`Character_${player.userId}`) as Character
			if (possibleExistingCharacter) {
				possibleExistingCharacter.setPosition(
					Phaser.Math.Interpolation.Linear([possibleExistingCharacter.x, player.x], 0.4),
					Phaser.Math.Interpolation.Linear([possibleExistingCharacter.y, player.y], 0.4)
				)
			}
		}
	}
}
