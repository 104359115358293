import { Game } from '../../scenes/Game'
import { RubikText } from './RubikText'

const posY = 50
const width = 400
const paddingVertical = 100

export class ScoreBoard extends Phaser.GameObjects.Container {
	score0: RubikText
	score1: RubikText

	bg: Phaser.GameObjects.NineSlice
	constructor(scene: Game) {
		super(scene, scene.cameras.main.width / 2, posY)

		this.bg = scene.add.nineslice(0, 0, 'blue_panel', undefined, width, posY * 2 + 20, 20, 20, 20, 20)
		this.score0 = new RubikText(scene, -width / 2 + paddingVertical, 0, '0', { fontSize: '72px' }).setName('score0')
		this.score1 = new RubikText(scene, width / 2 - paddingVertical, 0, '0', { fontSize: '72px' }).setName('score1')

		this.add(this.bg)
		this.add(this.score0)
		this.add(this.score1)

		scene.events.on('P_UpdateScore', (p: { data: { item: number; key: number } }) => {
			if (!this.scene) return
			if (p.data.key === 0) {
				this.score0.text = `${p.data.item}`
			} else if (p.data.key === 1) {
				this.score1.text = `${p.data.item}`
			}
		})

		scene.add.existing(this)
	}
}
