import { HOUSE_OPTIONS, HOUSE_POS_X, HOUSE_POS_Y, HOUSE_SIZE_H, HOUSE_SIZE_W } from '@shared/config/environment'
import { Game } from '../../scenes/Game'

export class House extends Phaser.Physics.Matter.Sprite {
	constructor(scene: Game) {
		super(scene.matter.world, HOUSE_POS_X, HOUSE_POS_Y - HOUSE_SIZE_H / 2, 'houseAlt1')
		scene.add.existing(this)

		this.setDisplaySize(HOUSE_SIZE_W, HOUSE_SIZE_H)

		this.setName('house')
		this.setRectangle(HOUSE_SIZE_W, HOUSE_SIZE_H, HOUSE_OPTIONS)
	}
}
