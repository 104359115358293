import {
	CHARACTER_CIRCLE_SIZE,
	CHARACTER_MOVE_SPEED_X,
	CHARACTER_MOVE_SPEED_Y,
	CHARACTER_OPTIONS,
} from '@shared/config/game'
import Phaser from 'phaser'
import { Game } from '../../scenes/Game'
import { MainMenu } from '../../scenes/MainMenu'

export class Character extends Phaser.Physics.Matter.Sprite {
	cooldownBloat: boolean = false
	cooldownDurationBloat: number = 1000

	constructor(
		scene: Game | MainMenu,
		x: number,
		y: number,
		texture: string | Phaser.Textures.Texture,
		userId: string
	) {
		super(scene.matter.world, x, y, texture)
		this.setName(`Character_${userId}`)

		scene.add.existing(this)

		// this.setDisplaySize(CHARACTER_CIRCLE_SIZE * 2, CHARACTER_CIRCLE_SIZE * 2)
		this.setCircle(CHARACTER_CIRCLE_SIZE, CHARACTER_OPTIONS)

		this.setScale(1)
	}

	showEmote(emote: string) {
		const emoteSprite = this.scene.add.sprite(this.x, this.y, emote).setDisplaySize(128, 128).setScale(0)
		this.scene.add.tween({
			targets: emoteSprite,
			scaleX: 3,
			scaleY: 3,
			ease: 'Elastic.easeOut',
			duration: 1500,
			repeat: 0,
			onComplete: () => {
				if (!this.scene) return
				this.scene.add.tween({
					targets: emoteSprite,
					duration: 1000,
					alpha: 0,
					repeat: 0,
					onComplete: () => {
						emoteSprite.destroy()
					},
				})
			},
		})
	}

	up() {
		this.setVelocityY(-CHARACTER_MOVE_SPEED_Y)
	}

	down() {
		this.setVelocityY(CHARACTER_MOVE_SPEED_Y)
	}

	left() {
		this.setVelocityX(-CHARACTER_MOVE_SPEED_X)
	}

	right() {
		this.setVelocityX(CHARACTER_MOVE_SPEED_X)
	}

	bloat() {
		if (!this.cooldownBloat) {
			this.cooldownBloat = true

			this.scene.tweens.add({
				targets: this,
				scaleX: 1.5, // Scale the sprite to 150% of its original width
				scaleY: 1.5, // Scale the sprite to 150% of its original height
				ease: 'Expo.easeOut', // This easing gives a nice bouncy effect
				duration: 75, // Duration of the scale up in milliseconds
				yoyo: true, // Yoyo effect makes the tween reverse automatically
				repeat: 0, // No repeats
			})

			this.scene.time.delayedCall(this.cooldownDurationBloat, () => {
				this.cooldownBloat = false
			})
		}
	}
}
