import { CALC_GOAL_WIDHT, GOAL_HEIGHT, GOAL_OPTIONS, GOAL_POS_Y, GOAL_WIDTH } from '@shared/config/environment'
import { Game } from '../../scenes/Game'

export class Goal extends Phaser.Physics.Matter.Sprite {
	constructor(scene: Game, side: '0' | '1') {
		super(scene.matter.world, CALC_GOAL_WIDHT(side), GOAL_POS_Y, 'houseAlt1')
		scene.add.existing(this)

		this.setVisible(false)

		this.setName(`goal_${side}`)
		this.setRectangle(GOAL_WIDTH, GOAL_HEIGHT, GOAL_OPTIONS)
	}
}
