export class RubikText extends Phaser.GameObjects.Text {
	constructor(
		scene: Phaser.Scene,
		x: number,
		y: number,
		text: string | string[],
		style?: Phaser.Types.GameObjects.Text.TextStyle
	) {
		super(scene, x, y, text, {
			...{
				fontFamily: "'Rubik', Inter, system-ui, Avenir, Helvetica, Arial, sans-serif",
				color: '#ffffff',
				fontSize: '32px',
				fontStyle: 'bold',
				stroke: 'black',
				align: 'center',
				strokeThickness: 6,
			},
			...style,
		})
		this.setOrigin(0.5, 0.5)
		this.setResolution(3)
		scene.add.existing(this)
	}
}
