import { Scene } from 'phaser'
import { ColyseusClient } from '../../colyseusClient/colyseus'
import { setupDiscordSdk } from '../../discord/authApiClient'

export class Preloader extends Scene {
	constructor() {
		super('Preloader')
	}

	init() {
		// add a loader
	}

	preload() {
		this.load.image('backgroundColorFall', 'img/backgroundColorFall.png')
		this.load.image('backgroundColorGrass', 'img/backgroundColorGrass.png')
		this.load.image('blue_button07', 'img/blue_button07.png')
		this.load.image('houseAlt1', 'img/objects/houseAlt1.png')

		this.load.image('blue_panel', 'img/blue_panel.png')
		this.load.image('blue_button06', 'img/blue_button06.png')

		this.load.image('blue_circle', 'img/objects/blue_circle.png')

		this.load.image('emote_exclamation', 'img/emotes/emote_exclamation.png')
		this.load.image('emote_faceAngry', 'img/emotes/emote_faceAngry.png')

		this.load.image('trophy', 'img/icons/trophy.png')

		this.load.image('buffalo', 'img/characters/buffalo.png')
		this.load.image('chicken', 'img/characters/chicken.png')
		this.load.image('elephant', 'img/characters/elephant.png')
		this.load.image('parrot', 'img/characters/parrot.png')

		this.load.audio('switch_006', 'audio/switch_006.ogg')
		this.load.audio('select_008', 'audio/select_008.ogg')
		this.load.audio('jingles_HIT15', 'audio/jingles_HIT15.ogg')
	}

	async create() {
		try {
			const { discordSdk, room } = await this.connect()
			this.registry.set('discordSdk', discordSdk)
			this.registry.set('room', room)

			this.scene.start('MainMenu')
		} catch (e) {
			console.log('failed to connect to discordsdk and room')
		}
	}

	async connect() {
		const discordSdk = await setupDiscordSdk()
		const colyseusClient = new ColyseusClient()
		const room = await colyseusClient.init(discordSdk)
		return { discordSdk, room }
	}
}
