import { Player } from '@server/src/states/Player'
import { State } from '@server/src/states/State'
import { Room } from 'colyseus.js'
import { removeCustomEventListeners } from '../../misc/events'
import { MainMenu } from '../../scenes/MainMenu'

const lerp = 1

export class MainMenuEventManager {
	room: Room<State>
	scene: MainMenu

	constructor(scene: MainMenu) {
		this.room = scene.registry.get('room')
		this.room.removeAllListeners()

		removeCustomEventListeners(scene)
		this.scene = scene

		this.S2CListeners()
		this.PListeners()

		this.room.send('C2S_MainMenuStart')
	}

	S2CListeners() {
		this.room.onMessage('S2C_SelectCharacter', (p: { x: number; y: number; data: Player }) => {
			this.scene.events.emit('P_SelectCharacter', p)
		})

		this.room.onMessage('S2C_SpawnSelectCharacters', (p: { data: Player[] }) => {
			this.scene.events.emit('P_SpawnSelectCharacters', p)
		})

		this.room.onMessage('S2C_StartGame', () => {
			this.scene.scene.start('Game')
		})

		this.room.state.players.onRemove((player) => {
			const possibleExistingCharacter = this.scene.children.getByName(`Character_${player.userId}`)
			if (possibleExistingCharacter) {
				possibleExistingCharacter.destroy(true)
			}
		})
	}

	PListeners() {
		this.scene.events.on('P_CharacterSelect', (p: { data: string }) => {
			this.room.send('C2S_SelectCharacter', p)
			this.scene.sound.play('switch_006')
		})

		this.scene.events.on('P_StartGame', () => {
			this.room.send('C2S_StartGame')
		})

		this.scene.events.on('P_KeyboardInput', (p: { data: string }) => {
			const character = this.scene.character
			if (!character) return
			const key = p.data
			this.room.send('C2S_Input', { key })
		})
	}
}
