import { applyCursorBehaviour } from '../../misc/cursor'

export class CharacterSelectPanel extends Phaser.GameObjects.Container {
	selectableCharacters: string[] = ['buffalo', 'chicken', 'elephant', 'parrot']
	padding: number = 30

	constructor(scene: Phaser.Scene) {
		super(scene, 1600, 300)
		scene.add.existing(this)

		const bg = scene.add.nineslice(0, 0, 'blue_panel', undefined, 500, 500, 30, 30, 30, 30)

		this.setSize(bg.width, bg.height)
		this.add(bg)

		this.createUI()
	}

	createUI() {
		for (let i = 0; i < this.selectableCharacters.length; i++) {
			const xDir = i % 2 === 0 ? -1 : 1
			const yDir = i < 2 ? -1 : 1

			const posX = xDir * (this.width / 4 - this.padding / 2)
			const posY = yDir * (this.height / 4 - this.padding / 2)

			const frame = this.scene.add.nineslice(posX, posY, 'blue_button06', undefined, 200, 200, 10, 10, 10, 10)
			const selectable = this.scene.add.sprite(posX, posY, this.selectableCharacters[i]).setScale(0.9)

			frame.setInteractive()
			frame
				.on('pointerdown', () => {
					this.scene.events.emit('P_CharacterSelect', { data: this.selectableCharacters[i] })
				})
				.on('pointerover', () => {
					this.scene.sound.play('select_008')
				})
			applyCursorBehaviour(frame)
			this.add(frame)
			this.add(selectable)
		}
	}
}
