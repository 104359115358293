import { WORLD_SIZE } from '@shared/config/game'
import { WORLD_GRAVITY_X, WORLD_GRAVITY_Y } from '@shared/config/world'
import * as Phaser from 'phaser'
import { Boot } from './scenes/Boot'
import { Game } from './scenes/Game'
import { MainMenu } from './scenes/MainMenu'
import { PostLobby } from './scenes/PostLobby'
import { Preloader } from './scenes/Preloader'

const baseRuntimeConfig: Phaser.Types.Core.GameConfig = {
	type: Phaser.AUTO,
	parent: 'phaser',
	scale: {
		mode: Phaser.Scale.FIT,
	},
	...WORLD_SIZE,
	autoFocus: true,
	transparent: true,
	physics: {
		default: 'matter',
		matter: {
			gravity: { y: WORLD_GRAVITY_Y, x: WORLD_GRAVITY_X },
			debug: true,
		},
	},
}

export const phaserRuntimeConfig: Phaser.Types.Core.GameConfig = {
	...baseRuntimeConfig,
	scene: [Boot, Preloader, MainMenu, Game, PostLobby],
}
