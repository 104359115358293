import { State } from '@server/src/states/State'
import { BALL_SIZE, WORLD_SIZE } from '@shared/config/game'
import { Room } from 'colyseus.js'
import { Scene } from 'phaser'
import { Character } from '../objects/characters/Character'
import { GameCharacterManager } from '../objects/characters/GameCharacterManager'
import { Background } from '../objects/environment/Background'
import { BallIndicator } from '../objects/environment/BallIndicator'
import { GameEventManager } from '../objects/events/GameEventManager'
import { Ball } from '../objects/gameplay/Ball'
import { Goal } from '../objects/gameplay/Goal'
import { House } from '../objects/gameplay/House'
import { InputManager } from '../objects/input/CharacterInputManager'
import { ScoreBoard } from '../objects/ui/Score'

const timeInterval = 55
export class Game extends Scene {
	character: Character | null = null

	room!: Room<State>
	inputManager!: InputManager
	ball!: Ball
	ballIndicator!: BallIndicator

	syncTimer = 0

	ballPrevX: number = 0
	ballPrevY: number = 0

	constructor() {
		super('Game')
	}

	create() {
		this.room = this.registry.get('room')

		const characterManager = new GameCharacterManager(this)
		characterManager.spawnPlayers(Array.from(this.room.state.players.values()))

		this.ball = new Ball(this, WORLD_SIZE.width / 2, WORLD_SIZE.height / 2)
		new GameEventManager(this)

		this.sound.play('jingles_HIT15')

		new Background(this, 'backgroundColorGrass')
		new House(this)

		new Goal(this, '0')
		new Goal(this, '1')

		this.ballIndicator = new BallIndicator(this)
		new ScoreBoard(this)

		this.inputManager = new InputManager(this)
	}

	update(_time: number, _delta: number) {
		this.inputManager.handleInput()

		this.ball.setPosition(
			Phaser.Math.Interpolation.Linear([this.ball.x, this.ballPrevX], 0.4),
			Phaser.Math.Interpolation.Linear([this.ball.y, this.ballPrevY], 0.4)
		)

		for (const player of Array.from(this.room.state.players.values())) {
			const possibleExistingCharacter = this.children.getByName(`Character_${player.userId}`) as Character
			if (possibleExistingCharacter) {
				possibleExistingCharacter.setPosition(
					Phaser.Math.Interpolation.Linear([possibleExistingCharacter.x, player.x], 0.4),
					Phaser.Math.Interpolation.Linear([possibleExistingCharacter.y, player.y], 0.4)
				)
			}
		}

		this.ballIndicator.setX(this.ball.x)
		this.ballIndicator.setVisible(this.ball.y <= -BALL_SIZE)
	}
}
