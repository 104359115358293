import { BALL_OPTIONS, BALL_SIZE } from '@shared/config/game'
import { Game } from '../../scenes/Game'

export class Ball extends Phaser.Physics.Matter.Sprite {
	constructor(scene: Game, x: number, y: number, texture = 'blue_circle') {
		super(scene.matter.world, x, y, texture)

		scene.add.existing(this)

		this.setName('ball')
		this.setCircle(BALL_SIZE, BALL_OPTIONS)
	}
}
