export class BallIndicator extends Phaser.GameObjects.Container {
	constructor(scene: Phaser.Scene) {
		super(scene, scene.cameras.main.width / 2, 100)

		const indicator = scene.add.sprite(0, 0, 'emote_exclamation').setDisplaySize(128, 128)
		this.setDepth(100)

		this.add(indicator)
		this.setVisible(false)
		scene.add.existing(this)
	}
}
