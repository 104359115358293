import { WORLD_SIZE } from './game'

// HOUSE
export const HOUSE_SIZE_W = 300
export const HOUSE_SIZE_H = 550

export const HOUSE_POS_X = WORLD_SIZE.width / 2
export const HOUSE_POS_Y = WORLD_SIZE.height

export const HOUSE_OPTIONS = {
	isStatic: true,
}

// GOAL
export const GOAL_WIDTH = WORLD_SIZE.width / 2
export const GOAL_HEIGHT = 20
export const GOAL_POS_X = WORLD_SIZE.width / 2
export const GOAL_POS_Y = WORLD_SIZE.height

export const GOAL_OPTIONS = {
	isStatic: true,
}

export const CALC_GOAL_WIDHT = (side: '0' | '1') => {
	return GOAL_POS_X + (side === '0' ? -HOUSE_SIZE_W * 2 : HOUSE_SIZE_W * 2)
}
