import { GameResult } from '@server/src/states/GameResult'
import { State } from '@server/src/states/State'
import { Room } from 'colyseus.js'
import { removeCustomEventListeners } from '../../misc/events'
import { Game } from '../../scenes/Game'

const lerp = 0.1

export class GameEventManager {
	room: Room<State>
	scene: Game

	constructor(scene: Game) {
		this.room = scene.registry.get('room')
		this.room.removeAllListeners()

		removeCustomEventListeners(scene)
		this.scene = scene

		this.S2CListeners()
		this.PListeners()
	}

	S2CListeners() {
		this.room.state.gameState.scores.onChange((item, key) => {
			this.scene.events.emit('P_UpdateScore', { data: { item, key } })
		})

		this.room.state.listen('ballPos', (ballPos, _prevBallPos) => {
			if (!_prevBallPos) return

			this.scene.ballPrevX = ballPos.x
			this.scene.ballPrevY = ballPos.y
		})

		this.room.onMessage('S2C_Win', (p: { data: GameResult }) => {
			this.scene.scene.start('PostLobby', p)
		})
	}

	PListeners() {
		this.scene.events.on('P_KeyboardInput', (p: { data: string }) => {
			const character = this.scene.character
			if (!character) return
			const key = p.data
			this.room.send('C2S_Input', { key })
		})
	}
}
